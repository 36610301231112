import { debounce } from "lodash";
import React, { useCallback, useState } from "react";
import { ChromePicker, ColorChangeHandler, RGBColor } from "react-color";
import OutsideClickHandler from "react-outside-click-handler";
import styled from "styled-components";

const Preview = styled.div<{ color: string }>`
  background: ${props => props.color};
  border: 1px solid #c9cccf;
  border-radius: 50%;
  cursor: pointer;
  height: 35px;
  transition: background 0.05s ease-in-out;
  width: 35px;
`;

const Popover = styled.div`
  position: absolute;
  z-index: 1000;
`;

interface Props {
  defaultColor?: string;
  onChange(color: string): void;
}

const COLOR_DRAG_DEBOUNCE = 75;

const rgbToString = ({ r, g, b, a }: RGBColor): string => `rgba(${r}, ${g}, ${b}, ${a})`;

export function ColorPicker({ defaultColor = "#000", onChange }: Props): JSX.Element {
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleClick = useCallback(() => setShowColorPicker(show => !show), []);
  const handleClose = useCallback(() => setShowColorPicker(false), []);

  return (
    <React.Fragment>
      <Preview color={defaultColor} onClick={handleClick} />
      {showColorPicker ? (
        <Popover>
          <BaseChromePicker defaultColor={defaultColor} onChange={onChange} handleClose={handleClose} />
        </Popover>
      ) : null}
    </React.Fragment>
  );
}

interface BaseChromePickerProps {
  handleClose: (event: MouseEvent) => void;
  onChange(color: string): void;
  defaultColor?: string;
}

export const BaseChromePicker = ({ handleClose, onChange, defaultColor = "#000" }: BaseChromePickerProps) => {
  const [localColor, setLocalColor] = useState(defaultColor);

  const handleChangeDebounced = useCallback(
    debounce((colorString: string) => onChange(colorString), COLOR_DRAG_DEBOUNCE, {
      leading: true,
      trailing: true,
    }),
    []
  );
  const handleChangeLocal: ColorChangeHandler = useCallback(colorResult => {
    const value = !!colorResult.rgb.a && colorResult.rgb.a < 1 ? rgbToString(colorResult.rgb) : colorResult.hex;
    setLocalColor(value);
    handleChangeDebounced(value);
  }, []);

  return (
    <OutsideClickHandler onOutsideClick={e => handleClose(e)}>
      <ChromePicker color={localColor} onChange={handleChangeLocal} />
    </OutsideClickHandler>
  );
};
